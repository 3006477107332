<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="guaranteeCertificate"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->

          <b-card-body style="background-color:white;color:black">

            <div>

              <div
                style="color: black;
                text-align: center;
                font-weight: bold;
                font-size:20px"
              >
                <div>
                  <img
                    src="/logo.png"
                    alt="gsr"
                    width="120px"
                    height="auto"
                  >
                </div>
                <span>  Groupe Service Rapide</span>
                <br>
                Vente tout genre de clés brutes, machines et accessoires
                <br>
                <span style="text-size:18px">MF : 1082793 F/A/M/000</span>
                <br>
                <span style="text-size:18px">C.D 837979P</span>
              </div>
            </div>
            <div style="text-align:justify ;text-justify: inter-word;">
              <hr>
              <h5
                style="margin-top: 40px;
                font-size: 45px;
                line-height: 45px;
                color: black;
                text-align: center;
                font-weight: bold"
              >ATTESTATION DE GARANTIE
              </h5>

              <h3
                style="margin-top: 60px; color:black ;  line-height: 1.6;   "
              >
                Nous la société  <strong>Groupe Service Rapide</strong> sise à <strong> Dar chabaane Fehri Nabeul 8011</strong>
                nous confirmons A Monsieur <strong>{{ guaranteeCertificate.customer.company_name }}</strong> une <strong>« garantie produit » </strong> de <strong>24 mois</strong> pour la
                machine suivante / les Machines suivants :
                <ul>
                  <li
                    v-for="(item,index) in guaranteeCertificate.products"
                    :key="index"
                  >
                    {{ item.name }}
                  </li>

                </ul>
                <p style="font-size: 18px; margin-top: 30px; text-align: center; font-weight: bold; ">
                  ----                  </p>

                <h3 style="margin-top: 30px; color:black">
                  Nos produits sont conçus et fabriqués pour durer dans le temps, afin de vous
                  aider efficacement dans vos activités quotidiennes.</h3>

                <div style="text-align: right;color:black;font-size:20px;margin-top:100px">
                  <strong>Signature et cachet</strong>
                </div>

              </h3></div>
            <footer>

              <div style="display:flex; justify-content:space-between">
                <div style="display:flex;">
                  <feather-icon
                    icon="HomeIcon"
                    class="mr-25"
                  />
                  <h6 style="color:black;">
                    RUE EL KOUNAITRA 1-dar Chaabene El Fehri <br> Nabeul
                  </h6>
                </div>
                <div style="display:flex">
                  <feather-icon
                    icon="PhoneIcon"
                    class="mr-25"
                  />
                  <h6 style="color:black;">
                    (+216)97 607 740
                  </h6>
                </div>
                <div style="display:flex">
                  <feather-icon
                    icon="GlobeIcon"
                    class="mr-25"
                  />
                  <h6 style="color:black;">
                    gsr-cle1@hotmail.fr
                  </h6>
                </div>
                <div />
              </div></footer>

          </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printGaranteeCertificate"
          >
            Print
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from '@/libs/axios'
import format from 'date-fns/format'

export default {
  name: 'ShowguaranteeCertificate',
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
  },
  data() {
    return {
      guaranteeCertificate: null,
      format,
    }
  },
  created() {
    this.getGuaranteeCertificate()
  },
  methods: {
    async getGuaranteeCertificate() {
      try {
        const { data } = await axiosIns.get(`/guarantee-certificates/details/${this.$route.params.id}`)
        this.guaranteeCertificate = data
      } catch (error) {
        console.log(error)
      }
    },

  },
  setup() {
    const printGaranteeCertificate = () => {
      window.print()
    }

    return {
      printGaranteeCertificate,

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.ta {
    white-space: nowrap;
    font-size: 18px;
    color: black;
  }
th
 {
  padding: 10px;
  text-align: left;
}
.trborder{
  border-bottom: 1px solid black;
}

table {
  font-size: 18px;
  width: 100%;
}

.contact {
  background-color: #0b6746;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
   .ta {
    white-space: nowrap;
    overflow-x: visible;
    font-size: 12px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .tdt
  {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }
  .tht
  {
    padding: 10px;
    text-align: left;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
